// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Certificado de clientes</h2>
                <h3>Certificados de pruebas</h3>
                <p>
                    Recuerda que el cliente de prueba <b>deben usar certificados especiales</b> y no los certificados
                    reales de algún emisor.
                </p>
                <p>
                    Si un cliente de pruebas usara un certificado real, no verás ningún error hasta el momento que quieras emitir una factura. Por lo que es importante realizar el flujo de pruebas completo primero.
                </p>
                <p>
                    Una vez hayas realizado facturas de prueba satisfactorias, deberás dar de alta un nuevo cliente productivo y posteriormente cargar el CSD real.
                </p>
                <p>
                    <b>Recuerda que no es posible convertir un cliente emisor de prueba en un cliente productivo</b>. El cliente productivo deberá ser uno nuevo cuando hayas generado facturas de prueba correctamente.
                </p>
                <div className="row start">
                    <a href="/files/IVD920810GU2.zip" className="file">
                        CSD IVD920810GU2
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-5.55-8h-2.9v3H8l4 4 4-4h-2.55z"></path>
                        </svg>
                    </a>
                </div>
                <p>
                    <b>Contraseña de la llave: </b> 12345678a <br />
                </p>
                <h3>
                    Subir el certificado
                </h3>
                <p>
                    Subir los certificados se realiza en Front-End, subiendo los archivos como Form Data (x-www-form-urlencoded).
                    En el ejemplo verás una función declarada en JavaScript para browser, la cual no depende de ningun framework y puede ser llamada tal cual. <br />
                </p>
                <p>
                    Para ejecutar esta función usando un input normal de HTML puedes hacer lo siguiente.
                </p>
                <pre>
                    <code>
                        <b>POST:</b> https://api.fiscalpop.com/api/v1/upload/cer/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <pre>
                    <code className="language-html">
                        {`<input type="file" accept=".cer" onchange="uploadCertificate()">`}
                    </code>
                </pre>
                <h3>Respuesta del request</h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>keyPem: </b> Key del CSD en formato PEM
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>keyModulus: </b> El Modulus de la llave del CSD<br />
                            <span className="quote small">El Modulus es una valor de tipo MD5 de OPENSSL que encuentras en los certificados CSR (tal como los CSD).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cer: </b> El Cer en formato PEM
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cerNumber: </b> El número de serie del Certificado, como lo solicita el SAT.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>cerModulus: </b> <br />
                            <span className="quote small">El Modulus es una valor de tipo MD5 de OPENSSL que encuentras en los certificados CSR (tal como los CSD).</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>notBefore: </b> El CSD puede usarse a partir de esta fecha, no antes.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>notAfter: </b> Fecha de caducidad del CSD, después de esta fecha, no puede usarse.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> La llave de acceso del cliente, confirmando el token usado
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>masterToken: </b> Esta es <span className="error">la llave de acceso principal</span>, confirmando la clave usada para crear el cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>modulusMatch: </b> Valor true / false que indica si la llave pertenece al certificado <br />
                            <span className="small quote">Si el modulusMatch es true, y las fechas notBefore / notAfter son válidas, el cliente esta listo para facturar</span>
                        </p>
.                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/clientes-llaves`} previous={`/api/referencia/clientes-agregar`}/>
            </div>
            <div className="side">
            <CodeBlock title="Subir Cer CSD" requests={clienteScripts.certificados.requests} response={clienteScripts.certificados.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
